import { getAppVersion } from "app/utils"

export const WZ_FEEDBACK = "wz_feedback"
export const _P_BULLETIN_BOARD_ORGS = "_p_bulletin_board_orgs"
export const _P_EVENTS_VENUE_FILTER_ORGS = "_p_events_venue_filter_orgs"
export const _P_FULL_COUNT_ORGS = "_p_full_count_orgs"
export const _O_NEW_DOCUMENT_SEARCH_ORGS = "_o_new_document_search_orgs"
export const _P_NEW_DIRECTORY_SEARCH = "_p_new_directory_search"
export const _P_LANDING_PAGES = "_p_landing_pages";

export const L_P_RESIDENTS_TO_MEMBERS_ORGS = "l_p_residents_to_members_orgs"
export const L_O_AUTO_REFRESH_INTERVAL_SECONDS = "l_o_auto_refresh_interval_seconds"

export const L_O_LIFE_VERSION = "L_O_LIFE_VERSION"

export const _P_HIDE_MOOD_DIALOG = "_p_hide_mood_dialog"

// Elastic Search constants
export const L_P_HS_CALIBRATION_PANEL_ENABLED = "l_p_hs_calibration_panel_enabled"
export const L_O_ES_DOCUMENTS_SEARCH_MIN_SCORE = "l_o_es_documents_search_min_score"
export const L_O_ES_DOCUMENTS_KEYWORD_BOOST = "l_o_es_documents_keyword_boost"
export const L_O_ES_DOCUMENTS_SEMANTIC_BOOST = "l_o_es_documents_semantic_boost"

// Temporary Feature flags (these should be removed after the feature is verified and approved)
export const _P_OPT_OUT_FROM_BIRTHDAY_MESSAGES_ORG = "_p_opt_out_from_birthday_messages_orgs"

// Feature flag to manage video player logs
export const _OP_VIDEO_PLAYER_LOGS_ON = "_op_video_player_logs_on"

export const DEFAULT_FEATURE_FLAG_STATE = {
  wz_feedback: "true",
  l_o_auto_refresh_interval_seconds: 1200,
  L_O_LIFE_VERSION: getAppVersion().toString(),
  _p_bulletin_board_orgs: "",
  _p_events_venue_filter_orgs: "",
  _p_new_directory_search: false,
  _p_landing_pages: false,
  _p_full_count_orgs: "",
  _o_new_document_search_orgs: "",
  l_p_residents_to_members_orgs: "",
  l_p_hs_calibration_panel_enabled: false,
  l_o_es_documents_search_min_score: 5.0,
  l_o_es_documents_keyword_boost: 0.5,
  l_o_es_documents_semantic_boost: 0.5,
  _p_hide_mood_dialog: ""
}
