import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDialogState } from "reakit/Dialog"
import { gql, useApolloClient } from "@apollo/client"
import moment from "moment"
import getConfig from "next/config"
import { useCurrentUser } from "app/lib/CurrentUser"
import { useOrganization } from "app/common/Organization"

const MoodCheckinContext = React.createContext()

const todayMoodCheckinQuery = gql`
  query GetTodayCheckin($organizationId: ID!) {
    currentUser {
      id
      todayCheckin(organizationId: $organizationId) {
        id
        checkedOn
        lastCheckinAt
        mood
      }
    }
  }
`

function storeLastCheckin({ key, momentDate }) {
  window.localStorage.setItem(key, momentDate.toISOString())
}

export default function MoodCheckinProvider({ children }) {
  const [status, setStatus] = useState("fetching")
  const apolloClient = useApolloClient()
  const dialog = useDialogState({ modal: false })
  const currentUser = useCurrentUser()
  const organization = useOrganization()
  const [lastCheckinAt, setLastCheckinAt] = useState(null)
  const shouldQueryCheckin = currentUser.hasPermission("checkin:create")
  const [checkinRequested, setCheckinRequested] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const showDialog = useCallback(() => {
    dialog.show()
  }, [dialog])

  /**
   * Avoid last checkin errors when accessing the app with different organizations/users on the same browser
   */
  const localStorageKey = useMemo(
    () => `${organization?.id}_${currentUser?.id}_last_checkin_at`,
    [currentUser?.id, organization?.id]
  )

  useEffect(() => {
    const today = moment()
    const { publicRuntimeConfig } = getConfig()
    const isStaging = publicRuntimeConfig.RUNNING_ENV === "staging"
    let timer

    // TODO charlinho use env variable to set page timout
    if (status === "idle") {
      clearTimeout(timer)
      const minutes = isStaging ? 5 * 60000 : 10 * 60000
      timer = setTimeout(
        () => {
          setStatus("fetching")
          setRefresh(false)
        },
        refresh ? 1 : minutes
      )
    }

    if (status === "fetching") {
      if (organization?.isChildless && shouldQueryCheckin) {
        apolloClient
          .query({
            query: todayMoodCheckinQuery,
            variables: { organizationId: organization?.id },
            fetchPolicy: "network-only",
          })
          .then(({ data }) => {
            const lastApiCheckinAt =
              data?.currentUser?.todayCheckin?.lastCheckinAt
            const skipCheckinAt = window.localStorage.getItem(localStorageKey)
            if (!lastApiCheckinAt && !skipCheckinAt) {
              setRefresh(false)
              return setStatus("answering")
            }
            if (
              !lastApiCheckinAt &&
              skipCheckinAt &&
              today.isAfter(moment(skipCheckinAt), "day")
            ) {
              setRefresh(false)
              return setStatus("answering")
            }
            setRefresh(false)
            setStatus("idle")
            setLastCheckinAt(lastApiCheckinAt)
          })
      }
    }

    if (status === "answering" && checkinRequested) {
      return showDialog()
    }

    return () => clearTimeout(timer)
  }, [
    apolloClient,
    shouldQueryCheckin,
    localStorageKey,
    organization?.isChildless,
    showDialog,
    status,
    organization?.id,
    checkinRequested,
    refresh,
  ])

  const onFinishCheckin = useCallback(() => {
    dialog.hide()
    const today = moment()
    if (!lastCheckinAt || !today.isAfter(moment(lastCheckinAt), "day")) {
      storeLastCheckin({ key: localStorageKey, momentDate: moment() })
    }
    setStatus("idle")
  }, [dialog, lastCheckinAt, localStorageKey])

  const onCloseCheckin = useCallback(() => {
    dialog.hide()

    if (lastCheckinAt) {
      setStatus("idle")
    } else {
      setStatus("answering")
    }
  }, [dialog]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRefreshCheckin = useCallback(() => {
    setRefresh(true)
  }, [])

  return (
    <MoodCheckinContext.Provider
      value={{
        dialogState: dialog,
        showDialog,
        finishCheckin: onFinishCheckin,
        setLastCheckinAt: setLastCheckinAt,
        lastCheckinAt,
        checkinRequested,
        requestCheckin: setCheckinRequested,
        setStatus,
        status,
        refreshCheckin: onRefreshCheckin,
        closeCheckin: onCloseCheckin,
      }}
    >
      {children}
    </MoodCheckinContext.Provider>
  )
}

export const useMoodCheckinState = () => React.useContext(MoodCheckinContext)
